import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const Img1 = () => {
  return (
    <StaticImage
      placeholder="blurred"
      src="../../../images/img_coll_1.png"
      alt="collar inteligente Kolyy"
    />
  );
};

export default Img1;
