import React from "react";
import {
  shadow_container,
  subs_container,
  close_btn,
  text,
} from "./modal.module.scss";
import Table from "../../../reserva/components/Plan/components/Table";

const SubscriptionPlan = ({ openModal }) => {
  return (
    <div onClick={openModal} className={shadow_container}>
      <div className={subs_container}>
        <h3>Plan de suscripción</h3>
        <div className={close_btn} onClick={openModal}>
          X
        </div>
        <Table />
        <div className={text}>
          <p>Incluye:</p>
          <ul>
            <li>Localizador GPS.</li>
            <li>LIVE Tracking ilimitado.</li>
            <li>Tracking de actividad en pasos y tiempo.</li>
            <li>Historial de semanas, meses y años.</li>
            <li>Diagnóstico mensual de su estado de forma.</li>
            <li>Registro de paseos.</li>
            <li>Contenido personalizado.</li>
          </ul>
          <br />
          <br />
          <h3>¿Cuándo empiezo a pagar la suscripción?</h3>
          <br />
          <p>
            El pago de la suscripción se realizará a través de la app kolyy en
            el momento de recibir y activar el collar.
          </p>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPlan;
