import React from "react";
import {
  container,
  title,
  flex_scroll,
  card,
  img,
  info,
  info_title,
  bgColor,
} from "./feedbacks.module.scss";
import logo_vanguardia from "../../../images/icons/la_vanguardia_logo.jpg";
import rating from "../../../images/icons/rating.png";

import Feedback1 from "./img/Img1";
import Feedback2 from "./img/Img2";
import Feedback3 from "./img/Img3";

const feedbacks = [
  {
    name: "Carlos M.",
    img: <Feedback1 />,
    text:
      "“He alucinado con lo poco que pesa, la tela muy bien pensada fácil de limpiar. Muchísimas gracias por pensar tanto en ellos!”",
  },
  {
    name: "Antonio E.",
    img: <Feedback2 />,
    text:
      "“Estamos muy contentos con el collar. A mis dos perros ya les ha llegado y ahora queremos poder comprar el arnés para mi gata.”",
  },
  {
    name: "María R.",
    img: <Feedback3 />,
    text:
      "“¡Qué maravilla de collar y qué bien le queda a Audrey! El color es precioso, no pesa nada  y además se ve muy resistente.”",
  },
];
const Feedbacks = () => {
  return (
    <section className={container}>
      <div className={title}>
        <div
          data-sal="fade"
          data-sal-delay="100"
          data-sal-duration="1000"
          className={bgColor}
        ></div>
        <h2 data-sal="slide-up" data-sal-delay="100" data-sal-duration="1000">
          "Un collar salvavidas para perros."
        </h2>
        <img
          data-sal="slide-up"
          data-sal-delay="100"
          data-sal-duration="1000"
          src={logo_vanguardia}
          alt="“Un collar salvavidas para perros.” - La Vanguardia"
        />
      </div>
      <div
        data-sal="fade"
        data-sal-delay="100"
        data-sal-duration="1000"
        className={flex_scroll}
      >
        {feedbacks.map((feedback) => (
          <div className={card}>
            <div className={img}>{feedback.img}</div>
            <div className={info}>
              <div className={info_title}>
                <h3>{feedback.name}</h3>
                <img src={rating} alt="5 estrellas" />
              </div>
              <p>{feedback.text}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Feedbacks;
