import React from "react";
import { table, flex_container } from "../plan.module.scss";

const Table = () => {
  return (
    <section className={table}>
      <h4>Plan kolyy</h4>
      <div className={flex_container}>
        <div>
          <p style={{ fontSize: "19px" }}>Mensual</p>
          <h5>€4,99</h5>
          <p style={{ fontSize: "17px" }}>/mes</p>
          <br />
          <p>
            Pago de €4,99
            <br />
            una vez al mes.
          </p>
        </div>
        <div style={{ borderLeft: "2px solid #F1F1F1" }}>
          <p style={{ fontSize: "19px" }}>Anual</p>
          <h5>€3,99</h5>
          <p style={{ fontSize: "17px" }}>/mes</p>
          <br />
          <p>
            Pago de €47,88
            <br />
            una vez al año.
          </p>
          <br />
          <span>
            Ahorra un 20% respecto
            <br />
            al plan mensual.
          </span>
        </div>
      </div>
    </section>
  );
};

export default Table;
