// extracted by mini-css-extract-plugin
export var answer = "about-module--answer--1t0Kt";
export var bg_img = "about-module--bg_img--gOyW+";
export var desktop_container = "about-module--desktop_container--mmnyc";
export var faqs_container = "about-module--faqs_container--g0SVz";
export var flex_container = "about-module--flex_container--rUwYV";
export var flex_div = "about-module--flex_div--Hr05q";
export var green_container = "about-module--green_container--CoCi0";
export var question = "about-module--question--NBrYD";
export var see_more = "about-module--see_more--9fEY2";
export var subscribe_container = "about-module--subscribe_container--Z6Dil";