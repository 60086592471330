import React, { useState } from "react";
import {
  faqs_container,
  question,
  see_more,
  answer,
} from "../about.module.scss";

const FaQs = () => {
  const [open, setOpen] = useState(false);
  const [answerNumber, setAnswerNumber] = useState(false);

  const faqsCollection = [
    {
      index: 1,
      question: "¿Por qué existe una política de suscripción?",
      answer:
        "El collar Kolyy utiliza tecnología GPS para localizar a tu perro y utiliza las redes móviles para enviar la ubicación en tu teléfono móvil. Al igual que los dispositivos móviles, también usa una tarjeta SIM (Que lleva integrada) que requiere de datos para conectarse a la red. Estos datos tienen un costo que parte lo asume Kolyy y la otra parte Kolyy cobra mediante suscripción fija mensual o anual al cliente. De esta forma tu perro estará localizable las 24h y a salvo en caso de fuga o perdida.",
    },
    {
      index: 2,
      question: "¿Cómo puedo conseguir un collar?",
      answer:
        "Muy fácil, primero debes reservar el collar en la pestaña “Personaliza tu collar” eligiendo el color y la talla que mejor se adapta a tu perro y rellenando el formulario. La reserva no tiene ningún coste. Esta reserva te da el derecho a comprar el collar en el momento que esté disponible (En el apartado “personaliza tu collar” se detalla la disponibilidad). Cuando este disponible recibirás un enlace en tu correo para poder canjear tu reserva por la compra del collar.",
    },
    {
      index: 3,
      question: "¿Cuál es el precio del collar?",
      answer:
        "No queremos esconder el precio, ni mucho menos. Lo encontrarás en el aparatado “personaliza tu collar” junto con los precios de los diferentes planes de suscripción y el detalle de los servicios.",
    },
    {
      index: 4,
      question: "¿Cuándo se empieza a cobrar la suscripción?",
      answer:
        "El pago de la suscripción se realizará en el momento que recibas tu collar y se vincule con la App de Kolyy. En el momento de instalar la App en tu móvil y, activar el collar, podrás escoger el plan de suscripción mensual o anual. Una vez escogido el plan i activado el collar ya estará listo para disfrutarlo con tu perro y empezar con el pago de la suscripción.",
    },
    {
      index: 5,
      question: "¿El collar es resistente al agua?",
      answer:
        "¡Sí! El collar kolyy cumple con el grado de protección IP67 lo que permite que sea resistente al agua y sumergible a 1 metros de profundidad durante 30 minutos sin que afecte a su funcionamiento. También esta 100% protegido de elementos solidos como polvo y arena.",
    },
    {
      index: 6,
      question: "¿El collar es apto para todos los tamaños de perro?",
      answer:
        "El collar es Apto para perros con peso superior a 5Kg. Para perros pequeños, con peso inferior a 5Kg o un diámetro de cuello inferior a 28cm recomendamos el uso del Arnés kolyy. Por otro lado, no existe limitaciones para perros grandes o muy grandes.",
    },
    {
      index: 7,
      question: "¿Qué puedo hacer si mi collar no funciona?",
      answer:
        "Al vender unidades limitadas garantizamos una rápida respuesta a posibles problemas técnicos. Envía un correo a support@kolyy.com y, sino conseguimos dar con una solución telemática reemplazamos el collar rápidamente por uno nuevo. El collar Kolyy tiene una garantía de 2 años.",
    },
    {
      index: 8,
      question: "¿El GPS funciona por satélite o a través de cobertura móvil?",
      answer:
        "Por motivos de tamaño y optimización del dispositivo, el collar funciona a través de cobertura 2G, disponible en más del 95% del territorio nacional.",
    },
    {
      index: 9,
      question: "¿Puedo devolverlo si no me gusta?",
      answer:
        "Dispones de 14 días para poder devolver el collar en caso de que no quedes satisfecho. No obstante, deberás hacerlo en perfectas condiciones.",
    },
    {
      index: 10,
      question: "¿Mide constantes vitales como el pulso?",
      answer:
        "Por ahora la tecnología y los métodos existentes no permiten hacerlo de un modo fiable a través de un collar, pero estamos trabajando junto con los mejores especialistas y veterinarios para poder hacerlo en el futuro.",
    },
    {
      index: 11,
      question: "¿Que pasa si ya utilizo un arnés de paseo?",
      answer:
        "El collar Kolyy está pensado para monitorizar su actividad y localización tanto dentro como fuera de casa, por lo que puedes seguir usando su arnés de paseo de forma normal junto con el collar Kolyy cuando salgáis a pasear.",
    },
    {
      index: 12,
      question: "¿El GPS funciona si el collar se queda sin batería?",
      answer:
        "Como en todo dispositivo electrónico, si el collar se queda sin batería no puede seguir funcionando. El collar incorpora un modo en el que cuando queda poca batería, “apaga” todas las funciones para priorizar el uso del “GPS”.",
    },
    {
      index: 13,
      question:
        "¿Las funciones de localización del collar están disponibles si salgo de España?",
      answer:
        "El collar kolyy funciona en todos los países miembros de la Unión Europea, siempre sujeto a la cobertura y el operador de cada territorio. Listado de países UE 27: Alemania, Austria, Bélgica, Bulgaria, Chipre, Croacia, Dinamarca, Eslovaquia, Eslovenia, España, Estonia, Finlandia, Francia, Grecia, Hungría, Irlanda, Italia, Letonia, Lituania, Luxemburgo, Malta, Países Bajos, Polonia, Portugal, República Checa, Rumania y Suecia.",
    },
  ];

  const openFaq = (index) => {
    setAnswerNumber(index);
    setOpen(!open);
  };

  return (
    <div className={faqs_container}>
      <h2 data-sal="slide-up" data-sal-delay="100" data-sal-duration="1000">
        FAQs
      </h2>
      <div data-sal="fade" data-sal-delay="100" data-sal-duration="1000">
        {faqsCollection.map((faq) => {
          return (
            <div
              data-sal="slide-up"
              data-sal-delay="100"
              data-sal-duration="1000"
              style={{ cursor: "pointer" }}
              index={faq.index}
              value={faq.index}
              onClick={() => openFaq(faq.index)}
            >
              <div className={question}>
                <p>{faq.question}</p>
                <p className={see_more}>{answerNumber === faq.index && open ? "-" : "+"}</p>
              </div>
              <p
                className={answer}
                style={{
                  display:
                    answerNumber === faq.index && open ? "block" : "none",
                }}
              >
                {faq.answer}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FaQs;
