import React, { useEffect } from "react";
import { dots } from "./colorSwapper.module.scss";

const ColorSwapper = ({ setColor, color }) => {
  useEffect(() => {
    setColor(color);
  }, [color]);
  return (
    <div>
      <div className={dots}>
        <div
          onClick={() => setColor("#1F1F1F")}
          style={{
            background: "#1F1F1F",
            border: color === "#1F1F1F" && "3px solid #FFFFFF",
          }}
        ></div>
        <div
          onClick={() => setColor("#30AAAA")}
          style={{
            background: "#30AAAA",
            border: color === "#30AAAA" && "3px solid #FFFFFF",
          }}
        ></div>
        <div
          onClick={() => setColor("#812439")}
          style={{
            background: "#812439",
            border: color === "#812439" && "3px solid #FFFFFF",
          }}
        ></div>
      </div>
    </div>
  );
};

export default ColorSwapper;
