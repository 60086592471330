import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const Feedback2 = () => {
  return (
    <StaticImage
      placeholder="blurred"
      src="../../../../images/feedback_2.jpg"
      alt="collar inteligente Kolyy"
    />
  );
};

export default Feedback2;
