import React from "react";
import { img_collection, border_bottom } from "./imgCollection.module.scss";
import Img1 from "./Img1";
import Img2 from "./Img2";
import Img3 from "./Img3";

const ImgCollection = () => {
  return (
    <div className={img_collection}>
      <Img1 />
      <Img2 />
      <div className={border_bottom}>
        <Img3 />
      </div>
    </div>
  );
};

export default ImgCollection;
