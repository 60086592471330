import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const YellowCollar = () => {
  return (
    <StaticImage
      placeholder="blurred"
      src="../../../../../../../images/collar-inteligente-kolyy.png"
      alt="collar inteligente kolyy"
    />
  );
};

export default YellowCollar;
